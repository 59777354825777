import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Etiquetas",
    meta: { title: "Etiquetas" },
    component: () => import("../components/eticketa"),
  },
  {
    path: "/pasillos",
    name: "Pasillos",
    meta: { title: "Pasillos" },
    component: () => import("../components/pasillos"),
  },
  {
    path: "/valorbodega",
    name: "ValorBodega",
    meta: { title: "Valor Bodega" },
    component: () => import("../components/Dashboard"),
  },
  {
    path: "/admin",
    name: "Admin",
    meta: { title: "Admin" },
    component: () => import("../components/Admin"),
  },
  {
    path: "/bodega",
    name: "Bodega",
    meta: { title: "Bodega" },
    component: () => import("../components/bodega"),
  },
  {
    path: "/vale",
    name: "Vales",
    meta: { title: "Vales" },
    component: () => import("../components/vale"),
  },
  {
    path: "/delvale",
    name: "Eliminar Vale",
    meta: { title: "Eliminar Vale" },
    component: () => import("../components/valedelete"),
  },
  {
    path: "/fracttalapi",
    name: "FracttalApi",
    meta: { title: "FracttalApi" },
    component: () => import("../components/fracttalhoras"),
  },
  {
    path: "/reporte",
    name: "Reporte",
    meta: { title: "Reporte" },
    component: () => import("../components/Reporte"),
  },
  {
    path: "*",
    name: "404",
    meta: { title: "404" },
    component: () => import("../components/404"),
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  next();
});
export default router;
