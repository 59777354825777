<template>
  <v-container>
    <v-app>
      <v-navigation-drawer v-model="drawer" color="deep-orange lighten-2" app temporary>
        <v-list>
          <v-list-item class="px-2">
            <v-img :src="require('./assets/logomant.png')"></v-img>
          </v-list-item>
        </v-list>

        <v-divider></v-divider>

        <v-list nav>
          <v-list-item v-for="(item, i) in list_items" :key="i" :to="item.link">
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
      <v-app-bar app color="warning" dark clipped-left>
        <v-app-bar-nav-icon @click="drawer = true" />
        <v-spacer />
        <v-img
          class="justify-end"
          alt="Ariztia Logo"
          contain
          :src="require('./assets/logo.svg')"
          transition="scale-transition"
          max-width="15%"
          max-height="100%"
        />

        <v-img
          class="justify-end"
          alt="Mantenimiento Logo"
          contain
          :src="require('./assets/logomant.png')"
          max-width="15%"
          max-height="100%"
        />
      </v-app-bar>

      <v-main>
        <router-view />
      </v-main>
    </v-app>
  </v-container>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    //
    drawer: false,
    list_items: [
      {
        title: "Etiquetas",
        icon: "mdi-card-bulleted-outline",
        link: "/",
      },
      {
        title: "Estado Pasillos",
        icon: "mdi-poll",
        link: "/pasillos",
      },
      // {
      //   title: "Valor Bodega",
      //   icon: "mdi-cash",
      //   link: "/valorbodega",
      // },
      {
        title: "Bodega",
        icon: "mdi-cash",
        link: "/bodega",
      },
      {
        title: "Registrar vale",
        icon: "mdi-package-variant-closed-plus",
        link: "/vale",
      },
      {
        title: "Reporte",
        icon: "mdi-file-document",
        link: "/reporte",
      },
    ],
  }),
};
</script>
<style>
@import url("https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900");

.links {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
}
</style>
